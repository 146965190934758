import { createRouter, createWebHashHistory } from 'vue-router'

// const home = () => import('../view/')

// 动态导入 home 组件
const home = () => import('../view/index.vue')
// 动态导入 about 组件
const remind = () => import('../view/remind.vue')

const login = () => import('../view/login.vue')

const test = () => import('../view/test.vue')

const telephoneNotHandle = () => import('../view/collection/telephoneNotHandle.vue')

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/remind',
    name: 'remind',
    component: remind
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/telephoneNotHandle',
    name: 'telephoneNotHandle',
    component: telephoneNotHandle
  },
  {
    path: '/test',
    name: 'test',
    component: test
  }

]

export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})
