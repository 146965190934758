import { createApp } from 'vue'
import App from './App.vue'
import JsSIP from 'jssip'
import { router } from './router'
import Element from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)

app.config.globalProperties.$JsSIP = JsSIP
app.use(Element)
app.use(router)
app.mount('#app')
